<template>
  <div class="candidate-list-report mt-2">
    <CCard>
      <CCardBody>
        <CRow class="mb-3">
          <CCol md="6">
            <h6 class="text-primary">
              Job: {{ selectedColumn.job_title }} |
              {{ selectedColumn.job_display_uid }} ({{
                selectedColumn.column_name | toSentance
              }})
            </h6>
            <h6 class="text-primary">
              Total Candidates: {{ totalCandidates }}
            </h6>
          </CCol>
          <CCol md="6">
            <Pagination
              :total="totalCandidates"
              :perPage="candidatesPerPage"
              @pagechanged="pageChanged"
              :maxVisibleButtons="isMobile ? 3 : 5"
              :position="isMobile ? 'left' : 'end'"
            />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CCol
            v-for="(item, index) in jobCandidates"
            :key="index"
            sm="6"
            md="3"
          >
            <div v-if="isFetchingJobCandidate" class="skeleton-card"></div>
            <CandidateInfoCard v-else :item="item" />
          </CCol>
          <CCol
            class="d-flex justify-content-center p-4"
            v-if="!jobCandidates.length && !isFetchingJobCandidate"
          >
            <h1>No Data found!</h1>
          </CCol>
          <CCol md="12" v-if="!jobCandidates.length && isFetchingJobCandidate">
            <CRow>
              <CCol v-for="(item, index) in 10" :key="index" sm="6" md="3">
                <div class="skeleton-card"></div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import CandidateInfoCard from "@/containers/JobCandidateStatus/CandidateInfoCard";
import Pagination from "@/components/reusable/Pagination";
import _ from "lodash";
export default {
  props: {
    totalCandidates: {
      type: Number,
      default: 0,
    },
    jobCandidates: {
      type: Array,
      default: [],
    },
    isFetchingJobCandidate: {
      type: Boolean,
      default: false,
    },
    selectedColumn: {
      type: Object,
      default: {},
    },
  },
  components: {
    CandidateInfoCard,
    Pagination,
  },
  data() {
    return {
      candidatesPerPage: 10,
      isMobile: false,
    };
  },
  methods: {
    pageChanged(pageNumber) {
      this.$emit("pageChanged", pageNumber);
    },
  },
  filters: {
    toSentance(data) {
      if (data) return _.startCase(data);
      return data;
    },
  },
};
</script>