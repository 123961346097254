<template>
  <div class="config-management-report-modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
    >
      <template #header>
        <h6 class="modal-title">Configuration Report</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          class="btn px-4 btn-outline-primary"
          @click="modalCallBack(false)"
        >
          Close
        </CButton>
        <CButton class="px-4 btn-primary" @click="onSubmit()">Save</CButton>
      </template>
      <div>
        <table
          class="table table-striped table-hover text-center border-bottom mb-0"
        >
          <thead>
            <tr style="background-color: lightgrey">
              <td style="width: 7%">
                <label>
                  <input
                    id="all"
                    name="selectAll"
                    type="checkbox"
                    :checked="isSelectedAll"
                    @change="handleSelectAll($event)"
                  />
                </label>
              </td>
              <td style="font-weight: 600">Select the management fields</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="i in managementFields"
              :key="i.code"
              :style="`${
                selected.includes(i.code)
                  ? 'background-color: #D0F0C0'
                  : 'background-color: white'
              }`"
            >
              <td>
                <label class="form-checkbox">
                  <input
                    :id="i.code"
                    :name="i.code"
                    type="checkbox"
                    :value="i.code"
                    v-model="selected"
                  />
                </label>
              </td>
              <td>
                {{ i.label }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "ConfigManagementReport",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: [],
    managementFields: [
      {
        label: "Matched",
        code: "matched",
      },
      {
        label: "Applied",
        code: "applied",
      },
      {
        label: "ShortList",
        code: "shortlist",
      },
      {
        label: "Submitted",
        code: "submitted",
      },
      {
        label: "Scheduled",
        code: "scheduled",
      },
      {
        label: "Interviewed",
        code: "interviewed",
      },
      {
        label: "Offered",
        code: "offered",
      },
      {
        label: "Joined",
        code: "joined",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getUserConfiguration"]),
    isSelectedAll() {
      const codes = this.managementFields.map(({ code }) => code);
      return _.difference(codes, this.selected).length === 0 || false;
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    handleSelectAll(e) {
      if (e?.target?.checked)
        this.selected = this.managementFields.map(({ code }) => code) || [];
      else this.selected = [];
      return;
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    onSubmit() {
      if (!this.selected?.length) {
        this.showToast({
          class: "text-white bg-danger",
          message: "Please Select Atleast one value",
        });
        return;
      }
      this.$emit("modalCallBack", true, this.selected);
    },
  },
  mounted() {
    if (this.getUserConfiguration?.allowed_fields?.length) {
      const { allowed_fields } = this.getUserConfiguration;
      this.selected =
        this.managementFields
          .filter(({ code }) => allowed_fields.includes(code))
          .map(({ code }) => code) || [];
    } else {
      this.selected = this.managementFields.map(({ code }) => code) || [];
    }
  },
};
</script>

<style lang="scss" scoped>
table tbody tr td {
  font-weight: 300;
}
</style>
