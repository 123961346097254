<template>
  <div class="report fs-13 position-relative d-flex justify-content-around">
    <CCard>
      <CCardBody>
        <CustomDataTable
          :striped="true"
          :bordered="true"
          :small="true"
          :items="reportData"
          :fields="fields"
          :items-per-page="10"
          sorter
          pagination
          :tableFilter="{ label: 'Search', placeholder: 'Job ID / Job Title' }"
          class="border px-1 pb-2"
          @page-change="tablePagination"
          :activePage="activePage"
        >
          <template #additional-filter>
            <div class="px-0 mt-1">
              <CRow class="mx-0 justify-content-between">
                <div class="form-inline pl-0 mr-4 mt-2">
                  <label class="mx-1 mb-0">Job Groups</label>
                  <div class="job-group">
                    <Select
                      name="group_ids"
                      :options="(options && options['group_ids']) || []"
                      :value="filter.group_ids"
                      :multiple="true"
                      :clearable="false"
                      @input="handleSelectForFilter"
                    />
                  </div>
                </div>
                <div class="form-inline pl-0 mr-4 mt-2">
                  <label class="mx-1 mb-0">Candidate Type</label>
                  <div class="candidate-type">
                    <Select
                      name="candidate_type_ids"
                      :options="
                        (options && options['candidate_type_ids']) || []
                      "
                      :value="filter.candidate_type_ids"
                      :multiple="true"
                      :clearable="false"
                      @input="handleSelectForFilter"
                    />
                  </div>
                </div>
                <div class=" mt-2 ml-auto">
                  <CButton
                    class="btn-link text-primary text-nowrap pr-0"
                    v-c-tooltip="`Download as CSV`"
                    @click="downloadDoc({ all_rows: true })"
                  >
                    Download All Jobs
                    <em class="fa-solid fa-download ml-1"></em>
                  </CButton>
                  <CButton
                    class="btn-link text-primary"
                    v-c-tooltip="`Configuration`"
                    @click="modifyConfig()"
                  >
                    <em class="fas fa-cog cursor-pointer"></em>
                  </CButton>
                </div>
              </CRow>
            </div>
          </template>
          <template #job_display_uid="{ item }">
            <td>
              <CButton
                v-if="!isManagementReviewer"
                class="btn-link text-left p-0"
                @click="navToJobDetail(item)"
                >{{ item.job_display_uid }}</CButton
              >
              <span v-else>{{ item.job_display_uid }}</span>
            </td>
          </template>
          <template #job_title="{ item }">
            <td>
              <span
                class="cursor-pointer"
                v-c-tooltip="{
                  content: 'Click to View Job Details',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="openJobDetailsModal(item.job_id)"
              >
                <i class="fa-solid fa-circle-info text-primary"></i>
              </span>
              <CButton
                v-if="!isManagementReviewer"
                class="btn-link text-left p-0 jobtitle"
                @click="navToJobDetail(item)"
                >{{ item.job_title }}</CButton
              >
              <span v-else class="mr-1">{{ item.job_title }}</span>
            </td>
          </template>
          <template #download="{ item }">
            <td>
              <CButton
                class="btn-link"
                v-c-tooltip="`Download as CSV`"
                @click="downloadDoc({ job_id: item.job_id })"
              >
                <i class="fa-solid fa-download text-primary"></i>
              </CButton>
            </td>
          </template>
          <template #details="{ index }">
            <CCollapse
              v-if="collapseIndex === index"
              :show="collapseIndex === index"
            >
              <AccordionList
                :info="accordionData"
                :currentPayload="currentPayload"
              />
            </CCollapse>
          </template>
          <template #matched="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('matched', item) }"
                @click="fetchJobCandidate('matched', item, index)"
                :disabled="!item.matched"
                >{{ item.matched }}</CButton
              >
            </td>
          </template>
          <template #applied="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('applied', item) }"
                @click="fetchJobCandidate('applied', item, index)"
                :disabled="!item.applied"
                >{{ item.applied }}</CButton
              >
            </td>
          </template>
          <template #shortlist="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('shortlist', item) }"
                @click="fetchJobCandidate('shortlist', item, index)"
                :disabled="!item.shortlist"
                >{{ item.shortlist }}
              </CButton>
            </td>
          </template>
          <template #submitted="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('submitted', item) }"
                @click="fetchJobCandidate('submitted', item, index)"
                :disabled="!item.submitted"
                >{{ item.submitted }}</CButton
              >
            </td>
          </template>
          <template #scheduled="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('scheduled', item) }"
                @click="fetchJobCandidate('scheduled', item, index)"
                :disabled="!item.scheduled"
                >{{ item.scheduled }}</CButton
              >
            </td>
          </template>
          <template #interviewed="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('interviewed', item) }"
                @click="fetchJobCandidate('interviewed', item, index)"
                :disabled="!item.interviewed"
                >{{ item.interviewed }}
              </CButton>
            </td>
          </template>
          <template #offered="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('offered', item) }"
                @click="fetchJobCandidate('offered', item, index)"
                :disabled="!item.offered"
                >{{ item.offered }}</CButton
              >
            </td>
          </template>
          <template #joined="{ item, index }">
            <td>
              <CButton
                class="btn-link"
                :class="{ isActive: isSelected('joined', item) }"
                @click="fetchJobCandidate('joined', item, index)"
                :disabled="!item.joined"
                >{{ item.joined }}</CButton
              >
            </td>
          </template>
        </CustomDataTable>
      </CCardBody>
    </CCard>
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
    <config-management-report
      v-if="configManagementModal.isShowPopup"
      :isShowPopup="configManagementModal.isShowPopup"
      @modalCallBack="configManagementModalCallBack"
    />
  </div>
</template>
<script>
import m from "moment";
import _ from "lodash";
import {
  isEmptyObjectCheck,
  isObject,
  getFilterQueryStringWithoutArray,
} from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select";
import AccordionList from "@/containers/ManagementReport/AccordionList";
import JobInfoModal from "@/components/reusable/JobInfoModal";
import CustomDataTable from "@/components/reusable/CustomDataTable";
import ConfigManagementReport from "@/containers/ManagementReport/ConfigManagementReport.vue";
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    selectedColumn: {
      type: Object,
      default: {},
    },
    currentPayload: {
      type: Object,
      default: {},
    },
    filter: {
      type: Object,
      default: {},
    },
  },
  components: {
    Select,
    AccordionList,
    JobInfoModal,
    ConfigManagementReport,
    CustomDataTable,
  },
  data() {
    return {
      // sorter: { external: true, resetable: true },
      activePage: 1,
      collapseIndex: null,
      accordionData: {
        column_name: null,
        data: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
      configManagementModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedColumn",
      "currentUserType",
      "candidateType",
      "getUserConfiguration",
      "getJobGroups",
    ]),
    options() {
      return {
        candidate_type_ids:
          [{ label: "All", code: 0 }, ...this.candidateType] || [],
        group_ids: [
          { label: "All", code: 0 },
          ...this.getJobGroups.map((val) => ({
            label: val.group_name,
            code: val.group_id,
          })),
        ],
      };
    },
    fields() {
      let fields = [
        { key: "job_display_uid", label: "Job ID", _style: "width: 8%" },
        { key: "job_title", _style: "width: 15%" },
        {
          key: "expiry_review_date",
          label: "Expiry Date",
          _style: "width: 15%",
          filter: false,
        },
      ];
      let allowed_fields = {
        matched: 1,
        applied: 2,
        shortlist: 3,
        submitted: 4,
        scheduled: 5,
        interviewed: 6,
        offered: 7,
        joined: 8,
      };
      if (this.getUserConfiguration?.allowed_fields) {
        let sorted_allowed_fields = this.getUserConfiguration?.allowed_fields;
        sorted_allowed_fields.sort(function (a, b) {
          return allowed_fields[a] - allowed_fields[b];
        });
        for (let i in sorted_allowed_fields) {
          fields.push({
            key: sorted_allowed_fields[i],
            _style: "width: 8%",
            filter: false,
          });
        }
      } else {
        allowed_fields = Object.keys(allowed_fields);
        for (let i in allowed_fields) {
          fields.push({
            key: allowed_fields[i],
            _style: "width: 8%",
            filter: false,
          });
        }
      }
      return [
        ...fields,
        { key: "download", _style: "width: 5%", filter: false },
      ];
    },
    reportData() {
      return (
        (this.items &&
          this.items.map((v) => ({
            ...v,
            expiry_review_date: this.formatDate(v?.expiry_review_date),
            shortlist: v?.shortlist_1 + v?.shortlist_2,
          }))) ||
        []
      );
    },
    isManagementReviewer() {
      return this.currentUserType?.code === 6 || false;
    },
  },
  methods: {
    ...mapActions([
      "downloadManagementReport",
      "setSelectedColumn",
      "createUserConfiguration",
      "updateUserConfiguration",
      "fetchCandidateType",
      "fetchJobGroups",
    ]),
    tablePagination(pagination) {
      let query = this.$route.query || {};
      if (query.pagination) delete query.pagination;
      this.$router.push(
        `/management-report?${getFilterQueryStringWithoutArray({
          ...query,
          pagination,
        })}`
      );
    },
    downloadDoc(payload) {
      this.downloadManagementReport(payload);
    },
    formatDate(date, formatString = "DD MMM YYYY") {
      if (date) return m(date).format(formatString);
      return "--";
    },
    fetchJobCandidate(column_name, item, index) {
      if (this.collapseIndex != index) {
        this.accordionData.column_name = column_name;
        this.accordionData.data = item;
        this.collapseIndex = index;
        this.$emit("columnClick", column_name, item);
      } else {
        this.collapseIndex = null;
        let query = this.$route.query || {};
        const { pagination } = query;
        const q = pagination ? `pagination=${pagination}` : "";
        this.$router.push(`/management-report?${q}`);
        this.setSelectedColumn(null);
      }
    },
    handleSelectForFilter(name, value) {
      const isAllSelected =
        (value.length && value[value.length - 1].code === 0) || false;
      const opt = this.options?.[name]?.filter(({ code }) => code) || [];
      const optAll = [{ code: 0, label: "All" }];
      const isAll = _.isEqual(value, opt);
      const val =
        value.length === 0
          ? optAll
          : value.length > 0 && (isAllSelected || isAll)
          ? [{ code: 0, label: "All" }]
          : value.filter(({ code }) => code);
      Vue.set(this.filter, name, val);
      this.$emit("updateFilter", this.filter);
    },
    handleJobGroupSelect(name, value) {
      const isAllSelected =
        (value.length && value[value.length - 1].code === 0) || false;
      const opt = this.jobGroups?.filter(({ code }) => code) || [];
      const optAll = [{ code: 0, label: "All" }];
      const isAll = _.isEqual(value, opt);
      const val =
        value.length === 0
          ? optAll
          : value.length > 0 && (isAllSelected || isAll)
          ? [{ code: 0, label: "All" }]
          : value.filter(({ code }) => code);
      Vue.set(this.filter, name, val);
      this.$emit("updateFilter", this.filter);
    },
    isSelected(name, item) {
      if (
        isObject(this.selectedColumn) &&
        !isEmptyObjectCheck(this.selectedColumn)
      ) {
        const { column_name, job_id } = this.selectedColumn;
        return (column_name === name && job_id === item.job_id) || false;
      }
      return false;
    },
    navToJobDetail(item) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${item?.job_id}` });
    },
    openJobDetailsModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
    modifyConfig() {
      this.configManagementModal.isShowPopup = true;
    },
    configManagementModalCallBack(action, payload) {
      if (action && payload) {
        let appendAction = null;
        if (!this.getUserConfiguration?.configuration_id)
          appendAction = this.createUserConfiguration(payload);
        else {
          let finalPayload = {
            ...this.getUserConfiguration,
            allowed_fields: payload,
          };
          appendAction = this.updateUserConfiguration(finalPayload);
        }
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.configManagementModal.isShowPopup = false;
          }
        });
      } else this.configManagementModal.isShowPopup = false;
    },
  },
  created() {
    const pageNo = this.$route?.query?.pagination || 1;
    this.activePage = parseInt(pageNo);
    const query = this.$route.query || {};
    const { job_id } = query;
    this.collapseIndex = this.reportData.findIndex((v) => v?.job_id == job_id);
    this.fetchJobGroups();
    if (!this.candidateType.length) {
      this.fetchCandidateType();
    }
  },
};
</script>
<style lang="scss" scoped>
.fs-13 {
  font-size: 13px !important;
}

.isActive {
  background-color: #ffff00 !important;
}
.jobtitle {
  margin-top: -22px !important;
  margin-left: 17px !important;
  display: flex;
}
.candidate-type,
.job-group {
  width: 100%;
}
@media (min-width: 768px) {
  .candidate-type,
  .job-group {
    width: auto;
    min-width: 220px;
  }
}
@media (max-width: 500px) {
  .form-inline {
    margin-top: 0.5rem;
  }
}
</style>
