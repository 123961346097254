<template>
  <div>
    <CCard class="accordion-list mb-0">
      <CCardBody>
        <div
          v-if="isFetchingJobCandidateSts"
          class="d-flex justify-content-center mt-2 mb-2"
        >
          <CSpinner
            color="primary"
            class="mx-5"
            style="width: 2rem; height: 2rem"
          />
        </div>
        <div v-else>
          <div class="table table-responsive">
            <table class="table table-striped border">
              <colgroup>
                <col span="1" style="width: 10%" />
                <col span="1" style="width: 25%" />
                <col span="1" style="width: 15%" />
                <col span="1" style="width: 15%" />
                <col span="1" style="width: 25%" />
                <col span="1" style="width: 10%" />
              </colgroup>
              <thead>
                <th>Candidate ID</th>
                <th>Full Name</th>
                <th>Type</th>
                <th v-if="isShortList">Status</th>
                <th>Eligibility (Level)</th>
                <th>Highest Qualification</th>
                <th>Joining Date</th>
              </thead>
              <tbody>
                <tr v-for="data in reportData" :key="data.candidate_uid">
                  <td>
                    <CButton
                      v-if="!isManagementReviewer"
                      class="btn-link"
                      @click="navToCandidate(data.candidate_uid)"
                    >
                      {{ data.candidate_display_uid }}
                    </CButton>
                    <span v-else>{{ data.candidate_display_uid }}</span>
                  </td>
                  <td>
                    <CButton
                      v-if="!isManagementReviewer"
                      class="btn-link pr-2"
                      @click="navToCandidate(data.candidate_uid)"
                      >{{ data.candidate_full_name }}</CButton
                    >
                    <span v-else class="mr-1">{{
                      data.candidate_full_name
                    }}</span>
                    <span
                      class="cursor-pointer"
                      v-c-tooltip="{
                        content: 'Click to View Candidate Details',
                        placement: 'left',
                        appendToBody: true,
                      }"
                      @click="openCandidateDetailsModal(data.candidate_uid)"
                    >
                      <i class="fa-solid fa-circle-info text-primary"></i>
                    </span>
                  </td>
                  <td>{{ data.candidate_type }}</td>
                  <td v-if="isShortList">{{data.status_display_name}}</td>
                  <td>{{ data.eligibility }}</td>
                  <td>{{ data.highest_qualification }}</td>
                  <td>{{ data.joining_date }}</td>
                </tr>
                <tr v-if="!reportData.length">
                  <td colspan="6">
                    <p class="text-muted text-center">No Data Found</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <CCol lg="12">
              <Pagination
                :total="getJobCandidatesByStatusIDCount"
                :perPage="candidatesPerPage"
                @pagechanged="pageChanged"
                :maxVisibleButtons="isMobile ? 3 : 5"
                :position="isMobile ? 'left' : 'end'"
              />
            </CCol>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      @modalCallBack="candidateInfoModalCallBack"
    />
  </div>
</template>
<script>
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import Pagination from "@/components/reusable/Pagination";
import { mapActions, mapGetters } from "vuex";
import {
  isEmptyObjectCheck,
  isObject,
  getFilterQueryStringWithoutArray,
} from "@/helpers/helper";
import m from "moment";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    currentPayload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: "candidate_first_name", label: "First Name" },
        { key: "candidate_sur_name", label: "Last Name" },
        { key: "candidate_display_uid", label: "Candidate ID" },
        { key: "joining_date", label: "Joining Date" },
      ],
      candidatesPerPage: 10,
      isMobile: false,
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
    };
  },
  components: {
    CandidateInfoModal,
    Pagination,
  },
  computed: {
    ...mapGetters([
      "getJobCandidatesByStatusID",
      "getJobCandidatesByStatusIDCount",
      "isFetchingJobCandidateSts",
      "displayStatusSubStatus",
      "currentUserType",
      "getSelectedColumn",
    ]),
    reportData() {
      return this.getJobCandidatesByStatusID.map((v) => ({
        candidate_type: v?.candidate?.candidate_type?.candidate_type,
        candidate_full_name: v?.candidate?.full_name,
        candidate_display_uid: v?.candidate?.display_uid,
        candidate_uid: v?.candidate?.candidate_uid,
        joining_date: this.formatDate(v?.candidate_joining_date) || "--",
        eligibility: v?.candidate?.candidate_level_name || "--",
        highest_qualification: v?.candidate?.highest_qualification_name || "--",
        status_display_name: v?.status?.display_text || '--',
      }));
    },
    isManagementReviewer() {
      return this.currentUserType?.code === 6 || false;
    },
    isShortList(){
      const {query}= this.$route;
      return query?.column_name === 'shortlist' || false;
    }
  },
  methods: {
    ...mapActions(["setCandidateListBackUrl", "fetchJobCandidatesReport"]),
    navToCandidate(candidate_uid) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.$router.push({ path: `/candidate/${candidate_uid}` });
      return false;
    },
    pageChanged(pageNumber) {
      const { skip, limit } = this.getSkipLimitBypage(pageNumber);
      const { job_id, ...rest } = this.currentPayload;
      let query = this.$route.query || {};
      query.page ? delete query.page : null;
      this.$router.push(
        `/management-report?page=${pageNumber}&${getFilterQueryStringWithoutArray(
          query
        )}`
      );
      this.fetchJobCandidatesReport({
        pagination: false,
        skip,
        limit,
        ...this.currentPayload,
      });
    },
    getSkipLimitBypage(pageNumber) {
      const candidatesPerPage = 10;
      const skip = (pageNumber - 1) * candidatesPerPage || 0;
      const limit = candidatesPerPage;
      return { skip, limit };
    },
    openCandidateDetailsModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    formatDate(date) {
      if (date) return m(date).format("DD MMM YYYY");
      return date;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
thead {
  background-color: #fafafa;
}
</style>